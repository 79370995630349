<template>
    <div class="ml-10 mr-10">

        <TitleBar :text="this.$t('menu.settings')"></TitleBar>

        <v-card
            class="mx-auto"
        >
            <v-list two-line>
                <div v-for="(item, index) in links" :key="item.tab">
                    <v-list-item :href="item.href" :target="item.href ? '_blank' : ''" :to="item.to">
                        <v-list-item-icon>
                            <v-icon color="secondary">
                                {{ item.icon }}
                            </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                            <v-list-item-subtitle>{{ item.description }}
                            </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-icon>
                            <v-icon v-if="item.href">mdi-open-in-new</v-icon>
                            <v-icon v-else>mdi-chevron-right</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                    <v-divider v-if="index < (links.length-1)" inset></v-divider>
                </div>
            </v-list>
        </v-card>

    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";

export default {
    name: "Settings",
    components: {TitleBar},
    data() {
        return {
            links: [
                {
                    icon: "mdi-domain",
                    title: this.$t("company.settings.details"),
                    description: this.$t("company.settings.details_description"),
                    to: "/company/settings/details"
                },
                {
                    icon: "mdi-bell",
                    title: this.$t("company.settings.notifications"),
                    description: this.$t("company.settings.notifications_description"),
                    to: "/company/settings/notifications"
                },
                {
                    icon: "mdi-palette",
                    title: this.$t("company.settings.customisation"),
                    description: this.$t("company.settings.customisation_description"),
                    to: "/company/settings/customisation"
                },
                {
                    icon: "mdi-receipt-send",
                    title: this.$t("company.settings.expense-reports"),
                    description: this.$t("company.settings.expense_reports_description"),
                    to: "/company/settings/expense-reports"
                },
                {
                    icon: "mdi-book-information-variant",
                    title: this.$t("company.settings.codes"),
                    description: this.$t("company.settings.codes_description"),
                    to: "/company/settings/accounting-codes"
                },
                {
                    icon: "mdi-account-child",
                    title: this.$t("company.settings.oauth"),
                    description: this.$t("company.settings.oauth_description"),
                    to: "/company/settings/oauth"
                }
                ,
                {
                    icon: "mdi-account-group",
                    title: this.$t("company.settings.users"),
                    description: this.$t("company.settings.users_description"),
                    href: "https://my.stantabcorp.com/apps/accounting/configure"
                }
            ]
        };
    },
    mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            },
            {
                text: this.$t("menu.settings"),
                to: "/company/settings",
                exact: true
            }
        ]);
    }
};
</script>

<style scoped>

</style>